/* ==========================================================================
Min Width
 ========================================================================== */
html {
    font-size: 0.78rem;
}

// Extra Small devices (landscape phones, 480px and up)
@media (min-width: 480px) {}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    html {
        font-size: 0.8rem;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    html {
        font-size: 0.9rem;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    html {
        font-size: 0.95rem;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    html {
        font-size: 1rem;
    }
}

/* ==========================================================================
Max Width
 ========================================================================== */

// Extra Small devices (landscape phones, 480px and up)

/* HOME */

.homeContainer {
    @media (min-width: 1000px) {
        padding-top: 230px;
    }

    @media (max-width: 1000px) {
        padding-top: 280px;
    }

    @media (max-width: 767px) {
        padding-top: 170px;
    }

    @media (max-width: 470px) {
        padding-top: 180px;
    }
}

@media (max-width: 370px) {

    #game-numero1,
    #game-numero2,
    #game-numero3,
    #game-numero4 {
        color: #fff !important;
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 479px) {
    .button-rounded.button-rounded-gradient-jugar {
        margin: 0 !important;
    }

    .flaticon-hand-finger-pressing-a-circular-ring-button:before {
        content: none;
    }

    .center-right-button {
        padding: 0;
    }

    .py-10 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .px-30 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .img-responsives {
        display: none;
    }

    header {
        .navbar-brand {
            display: block;
            text-align: center;
            margin: 6px 0;
            width: 100%;
        }

        /*.navbar-light .navbar-toggler,
        .navbar-right-section {
            height: 4rem;
            width: 4rem;
        }*/

        .navbar-light .navbar-toggler {
            border-color: none;
        }

        .navbar-right-section {
            margin-left: auto !important;
        }

        .media-body {
            text-align: left !important;
        }

        .navbar-data-client {
            margin-left: 1rem;
        }

        .navbar-data-client .text-user-account {
            right: auto;
            left: 0;
        }
    }

    .navbar-data-client {
        // .media-body{
        //     display: none;
        // }
    }

    .navbar-brand {
        margin-right: 1rem;
    }

    .reload-button {
        top: 4px;
        right: 20%;
        z-index: 99;
    }

    .icon-absolute {
        background-size: 22px;
        height: 23px;
        width: 24px;
    }

    .image-lotterie-resultados {
        width: 40% !important;
    }

    .background-logo {
        top: -46px !important;
        width: 35% !important;
        left: 35% !important;
    }

    .icon-clear {
        background-size: 12px;
    }


    .result-single-params {
        padding-top: 2em;
    }

    .button-rounded-gradient-resultados {
        margin-top: 1em !important;
    }

    .result-single-property-gris {
        padding-right: 0;
    }

    .numero-favorito {
        display: flex;
        flex-direction: column;

    }

    .numero-favorito div {
        margin: 5px 0;
    }

    .numero-favorito div:last-child {
        margin: 10px 0;
    }


    .image-lotterie-resultados {
        width: 50%;
    }

}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575px) {
    .navbar-brand-image {
        max-width: 100px;
    }

    .gameContainer {
        .image-lotterie {
            max-width: 120px;
            margin-right: 1rem;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 767px) {
    .bf {
        margin: 20px 0 !important;
    }

    .modal-content .button-rounded span {
        line-height: 22px;
    }

    .navbar-seccion {
        font-size: 1rem;
    }

    right .single-lotterie .single-lotterie-lg h5 span,
    .single-lotterie .single-lotterie-md h5 span {
        font-size: 32px;
    }

    .pt-2,
    .py-2 {
        padding-top: 2rem !important;
    }

    .twoColumnsLotterie {
        width: 100% !important;
    }

    .gameContainer {
        .image-lotterie {
            position: relative;
            top: 10px;
            margin-right: 4rem;
            margin-left: 4em;
        }

        .gameContainer-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .navbar-brand-image {
        width: 100%;
        max-width: 140px;
    }

    .banner-home .button-red {
        margin-top: 1.5rem;
    }

    .navbar .navbar-collapse {
        position: absolute;
        right: 0;
        top: 95%;
        /* width: 100%; */
        z-index: 999;
        padding: 5px 10px;
        border-radius: 10px;
    }

    .navbar .navbar-collapse-mobile {
        position: absolute;
        left: 0;
        top: -15px;
        width: 256px;
        height: 100vh;
        z-index: 999;
        padding: 25px;
        border-radius: 10px;
        background: #1D1828 !important;
    }


    .single-lotterie {
        .single-lotterie-lg {
            h5 {
                text-align: center;
                font-size: 1.2rem;
            }

            p {
                text-align: center;
                font-size: 1.2rem;
            }
        }
    }

    //Table custom
    .table-custom-data {
        overflow-x: scroll;
    }

    .table-custom-data-premio {
        overflow-x: scroll;
    }

    .tabs-page .custom-radio-button {
        label {
            padding: 5px 5px 5px 28px !important;
        }

        .check {
            height: 20px;
            width: 20px;

            &:before {
                height: 10px;
                width: 10px;
                top: 3px;
                left: 3.5px;
            }
        }
    }

    .input-group .single-input .text-muted {
        top: -24%;
        left: 21%;
        position: relative;
    }

    .custom-radio-button.custom-radio-button-inline {
        float: left;
        min-width: 115px;
    }

    #img-consulta {
        display: none;
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991px) {
    #jugar {
        position: absolute;
        left: -5px;
        right: -2px;
        bottom: -30px;
        padding: 5px;
    }

    .py-10 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .px-30 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .image-lotterie-resultados {
        width: 100%;
    }

    .button-rounded.button-rounded-gradient-jugar {
        padding: 10px 20px;
    }

    .background-logo {
        top: -46px;
        width: 80%;
        left: 20%;
    }

    .button-rounded-gradient-resultados {
        margin-top: 1em !important;
    }

    .numero-favorito {
        display: flex;
        flex-direction: column;

    }

    .numero-favorito div {
        margin: 5px 0;
    }

    .numero-favorito div:last-child {
        margin: 10px 0;
    }

    //Header
    .navbar-data-client {
        &:hover .text-user-account {
            display: block !important;
        }

        .text-user-account {
            display: none !important;
            position: absolute;
            background: linear-gradient(162.64deg, #1D1828 -3.77%, #40007F 98.61%);
            ;
            min-width: 150px;
            top: 100%;
            right: 0;
            z-index: 1;
            padding: .5rem;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            border-radius: 10px;

            span {
                display: block;
                color: white !important;
                margin-right: 0px !important;
                padding: .3rem 0px;
                border-radius: 5px;

                &:hover {
                    background: linear-gradient(264.09deg, #6C01D7 -37.63%, #FF279B 94.58%);
                    ;
                    color: white !important;
                }
            }

            strong {
                width: 100%;
                display: block;
                height: 0px;
                padding-bottom: 2px;
                line-height: 100;
                margin: 3px 0px;
                border-bottom: 2px solid white;
            }
        }
    }

    .input-group {
        input {
            font-size: 2rem;
            padding-left: .3rem !important;
            padding-right: .3rem !important;
            width: 100%;
        }
    }

    .single-lotterie {
        .single-lotterie-md {
            img {
                width: 100%;
            }

            h5 {
                font-size: 1.2rem;
                text-align: center;

                strong {
                    font-size: 1em;
                }
            }

            p {
                font-size: 1.2rem;
                text-align: center;
            }
        }

        .single-lotterie-lg {
            h5 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1.8rem;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 1199px) {
    .list-lotterie-home {
        .button-red {
            padding-left: .8em;
            padding-right: .8em;
            font-size: 0.8em;
        }
    }

    .button-rounded.button-rounded-consultar {
        padding: 10px 20px;
    }

    .button-rounded {
        span {
            font-size: 1.1em;
        }
    }

    .button-rounded-gradient-resultados {
        margin-top: 1em !important;
    }
}

/* ==========================================================================
Between Big SmarthPhones
 ========================================================================== */
@media (min-width: 576px) and (max-width: 768px) {

    //Results single page
    .result-single-page {
        padding: 0px;

        .single-result-buttons .col-md-6 {
            padding: 0px;

            &:first-child {
                margin-bottom: 0.8rem;
            }
        }
    }

    .reload-button {
        top: -1px;
        right: 16%;
        z-index: 99;
    }
}

/* ==========================================================================
Between Desktop and Tablet
 ========================================================================== */
@media (min-width: 576px) and (max-width: 1199px) {
    .single-lotterie {
        h5 {
            font-size: 1.15rem;
        }
    }
}

/* ==========================================================================
Between Desktop
 ========================================================================== */
@media (min-width: 992px) and (max-width: 1199px) {}

/* ==========================================================================
Between Tablet
 ========================================================================== */
@media (min-width: 768px) and (max-width: 991px) {

    .result-single .result-single-params .result-single-property,
    .result-single .result-single-params .result-single-property+div {
        text-align: center !important;
    }
}

.invisible {
    visibility: hidden !important;
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 575px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }

    .container-main {
        padding: 0;
    }

    .row-container {
        margin: 0;
    }

    .App {
        overflow: hidden;
    }
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }

    .container-main {
        padding: 0;
    }

    .row-container {
        margin: 0;
    }

    .App {
        overflow: hidden;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

@media (max-width: 475px) {
    .media.align-items-center.mb-4 {
        flex-direction: column-reverse;
    }
}


//Media querys
@media only screen and (max-width: 600px) {

    .table-responsive {
        overflow: auto;
        min-width: 400px !important;
    }


    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .single-modal-content-pru,
    .single-modal-content-credito-pru {
        background-color: #fff;
        padding: 20px 20px 0;
        border-radius: 5px;
        width: 300px;
        height: 400px;
        border-radius: 16px;
    }

    .custom-carousel-next-arrow-pago {
        position: absolute;
        right: -5px;
        top: 190px;
        width: 35px;
        height: 35px;
        background: url("../images/slick-prev.png") no-repeat center;
        background-color: #fff;
        border: none;
    }

    .custom-carousel-prev-arrow-pagos-prev {
        position: absolute;
        left: -8px;
        top: 190px;
        width: 35px;
        height: 35px;
        background: url("../images/slick-next-purple.png") no-repeat center;
        background-color: #FFFFFF;
        border: none;
    }

    .single-img-pago {
        height: 70px;
        width: 130px;
    }

    .h2-premio {
        color: #000 !important;
        font-size: 30px;
    }

    .span-premio {
        color: #736d77 !important;
        font-size: 12px;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-select: none;
    }

    .home-main-slider .slick-dots {
        text-align: center !important;
    }

    .navbar-footer {
        margin: 0;
        padding: 0;
    }

    .news-item-wrapper-pay-pago {
        margin-bottom: 30px;
        color: white;
    }

    /*.slick-initialized .slick-slide {
        height: 147px;
    }*/
    .news-item-wrapper {
        height: auto;
    }

    .single-news {
        height: auto;
    }

    .single-noticias {

        h4,
        p {
            padding: 0 10px;
        }
    }

    .single-new {
        height: 360px;
    }

    .container-pagos div {
        margin: 10px;
    }

    .logo-footer-lotti {
        width: 50%;
        margin: 0 auto;
    }

    .custom-carousel-next-arrow,
    .custom-carousel-prev-arrow {
        top: 150px;
    }

    .single-seccion-footer {
        text-align: center;

        @media (max-width:600px) {
            height: auto;
            border: none;
        }
    }

    .single-seccion-footer-final {
        text-align: center;

        @media (max-width:600px) {
            height: auto;
            border: none;
        }
    }

    .single-hr-footer {
        display: none;
    }

    .single-logo-gooter {
        text-align: center;
        padding-bottom: 20px;
    }

    .single-img-person {
        display: none;
    }

    .single-Detalle {
        top: 250px;
        left: 100px;
    }

    .navbar-collapse {
        background: linear-gradient(162.64deg, #1D1828 -3.77%, #40007F 98.61%);
    }

    .button-text-reverse-ver-loterias {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }

    .single-name {
        position: relative;
        top: -10px;
    }

    .logo-gana,
    .logo-oki {
        display: none;
    }

    .single-input {
        margin-bottom: 10px;
    }

    .container-main {
        padding: 0;
    }

    .row-container {
        margin: 0;
    }

    .App {
        overflow: hidden;
    }

    .single-lotterie .single-lotterie-lg h5 span {
        font-size: 37px;
    }

    .icon-clear {
        left: 40px;
        top: -5px;
    }

    .gameContainer .check-fraction {
        margin: 0 50px;
    }

    .table-custom-data {
        max-height: 400px;
        overflow: auto !important;


        .MuiDataGrid-columnHeader {
            width: 50%;
        }

        .MuiDataGrid-cell--textLeft {
            margin-left: 40px;
        }
    }


    .table-custom-data-cart {
        overflow: auto;

        .MuiDataGrid-cell--textLeft {
            margin-left: 40px;
        }
    }



    .table-custom-data-premio {
        max-height: 400px;
        overflow: auto;
    }

    .table {
        width: 70%;
    }

    .table-cart .fractionCart,
    .table-cart .numberCart span,
    .table-cart .serieCart {
        font-size: 18px;
    }

    .table-custom-data .custom-table-data-row {
        vertical-align: initial;
    }

    .table-custom-data-premio .custom-table-data-row {
        vertical-align: initial;
    }

    .table-cart .custom-row-reloads {
        font-size: 16px;
    }

    .font-montserrat-light-news {
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto !important;
        //white-space: nowrap;
    }

    .image-lotterie {
        width: 100%;
        position: relative;
    }

    .table-custom-data .react-bootstrap-table {
        min-width: 400px;
    }

    .table-custom-data-premio .react-bootstrap-table {
        min-width: 400px;
    }

    .table-cart .custom-row-reloads {
        white-space: nowrap;
    }

    .texto-responcive {
        margin-top: 0;
    }

    /*.boton-acomodar{
        margin-top: 20px;
        margin-bottom: 50px;
    }*/
}

//Media querys
@media only screen and (max-width: 337px) {
    .navbar-seccion {
        font-size: 0.8rem;
    }
}