  /* CountdownTimer.css */


  .countdown-timer-general {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5em;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }

  .countdown-item-row {
    display: flex;
    flex-direction: row;
  }

  .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }

  .countdown-item-text-left {
    margin: 0;
    text-align: left;
  }

  .countdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 5px;
  }

  .countdown-label {
    display: block;
    font-size: 0.8em;
  }

  .countdown-item-booton {
    text-align: right;
  }

  .countdown-value {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }


  .countdown-row {
    border: 2px solid #f30000;
  }

  .countdown-row2 {
    border: 2px solid #f30000;
  }

  .navbar-content {
    border: 2px solid #ee0000;
    padding: 10px;
  }


  .btn-block {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }

  .btn {
    border: none;
    cursor: pointer;
    border-radius: 30px;
  }

  .button-text1 {
    width: 100%;
    padding: 10px 25px;
    border: 0.1rem solid #000000;
    font-style: normal;
    border-radius: 30px;
    cursor: pointer;
    color: rgb(10, 0, 0);
    text-decoration: none;

  }


  .countdown-digit {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #181818;
    margin: 2px;
    font-size: 24px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }

  .borde2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .borde2 .nav-item {
    margin-right: 25px;
    /* Ajusta el valor según lo necesites */
  }


  .borde2 .button-text {
    margin-right: 25px;
    /* Espacio entre logos */
  }

  .borde3 {
    width: 100%;
  }

  .borde {
    width: 100%;
  }


  @media (max-width: 768px) {
    .borde3 {
      margin: 0;
    }

    .borde2 {
      display: flex;
      width: 100%;
    }

    .borde {}

    .countdown-timer-general {
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1em;
      padding: 10px;
      border-radius: 5px;
      width: 100%;
      height: auto;
      position: relative;
    }

    .countdown-item-text-left h2 {
      display: inline-block;
      display: inline-flex;
      text-align: center;
      margin-bottom: 0px;
      font-size: 15px;
      padding: 0%;
      align-items: flex-start;
    }

    .countdown-item-row {
      justify-content: space-between;
      margin-bottom: 0;
      max-height: 40px;
      width: 50%;
      position: relative;
      box-sizing: border-box;
    }

    .countdown-item {
      max-height: 40px;
      align-items: center;
      padding: 0;
      width: auto;
      box-sizing: border-box;
    }

    .countdown-label {
      font-size: 0.8em;
      background-attachment: fixed;
    }

    .countdown-item-button {
      padding: 0px;
      right: 0;
      top: 60%;
      right: 20px;
      width: auto;
      height: auto;
    }

    .btn {
      cursor: pointer;
      margin-top: -10px;
    }

    .btn-block {
      width: auto;
      margin-bottom: 0;
    }

    .btn {
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }

    .countdown-digit {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border: 1px solid #181818;
      margin: 2px;
      font-size: 20px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 480px) {
    .borde3 {
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      flex-direction: row;
      /* Cambia a filas */

    }

    .borde2 {
      width: 100%;
      display: grid;
      flex-direction: row;
      /* Coloca los elementos en columnas */
      align-items: flex-start;
      /* Alinea los elementos al inicio (izquierda) */
      justify-content: center;
    }

    .borde {}

    .countdown-timer-general {
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1em;
      padding: 10px;
      border-radius: 5px;
      width: 100%;
      height: auto;
      position: relative;
    }

    .countdown-item-text-left h2 {
      display: inline-block;
      display: inline-flex;
      text-align: start;
      margin-bottom: 0px;
      font-size: 15px;
      padding: 0%;
      align-items: flex-start;
    }

    .countdown-item-row {
      justify-content: space-between;
      margin-bottom: 0;
      max-height: 40px;
      width: 50%;
      position: relative;
      box-sizing: border-box;
    }

    .countdown-item {
      max-height: 40px;
      align-items: center;
      padding: 2%;
      width: auto;
      box-sizing: border-box;
    }

    .countdown-label {
      font-size: 0.8em;
      background-attachment: fixed;
    }

    .countdown-item-button {
      padding: 0px;
      right: 0;
      top: 50%;
      right: 0px;

      width: 80px;
      height: 25px;
    }

    .btn {
      cursor: pointer;
      margin-top: -10px;
    }

    .btn-block {
      width: auto;
      margin-bottom: 0;
    }

    .btn {
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding-top: 12px;
    }

    .btn-primary-custom {
      margin-top: 20px;
    }

    .countdown-digit {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border: 1px solid #181818;
      margin: 2px;
      font-size: 20px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
  }